<template>
  <div class="container">
    <div class="row">
      <div class="container-title col-md-12">
        <h2>expérience</h2>
      </div>
    </div>
    <div class="row">
      <div id="experience_info" class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>informatique</h4>
          </div>
          <div :key="exp.id" v-for="exp in expInfo">
            <h5 class="date">
              {{ exp.date }}
            </h5>
            <ul>
              <li class="arrow-circle employeur" title="Employeur">
                {{ exp.employeur }}
              </li>
              <li class="role white-hat" title="Rôle(s)">{{ exp.poste }}</li>
              <li
                title="Tâches"
                v-for="(t, i) in exp.taches"
                :key="`exp.taches-${i}`"
                class="arrow taches"
              >
                {{ t }}
              </li>

              <ul
                class="languages"
                v-for="(s, i) in exp.stack"
                :key="`exp.stak-${i}`"
              >
                <li class="stack">
                  <b-badge pill variant="dark">{{ s }}</b-badge>
                </li>
              </ul>
            </ul>
          </div>
        </section>
      </div>

      <div id="experience_art" class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>artistique</h4>
          </div>
          <div :key="exp.id" v-for="exp in expArt">
            <h5 class="date">
              {{ exp.date }}
            </h5>
            <ul>
              <li title="Employeur" class="arrow-circle employeur">
                {{ exp.employeur }}
              </li>
              <li title="Rôle" class="role white-hat">{{ exp.poste }}</li>
              <li
                title="Tâches"
                v-for="(t, i) in exp.taches"
                :key="`exp.taches-${i}`"
                class="arrow taches"
              >
                {{ t }}
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExperienceComponent",
  data() {
    return {
      visible: true,
    };
  },
  props: {
    expInfo: { type: Array, requried: true },
    expArt: { type: Array, requried: true },
  },
  created() {
    this.expInfo.forEach((d) => {
      if (typeof d === "object") {
        d.visible = true;
        if (Array.isArray(d.programme)) d.isProgArray = true;
        if (d.hasOwnProperty("plus")) d.havePlus = true;
        if (d.hasOwnProperty("language")) d.haveLang = true;
      }
    });
    this.expArt.forEach((d) => {
      if (typeof d === "object") {
        d.visible = true;
        if (Array.isArray(d.programme)) d.isProgArray = true;
        if (d.hasOwnProperty("plus")) d.havePlus = true;
        if (d.hasOwnProperty("language")) d.haveLang = true;
      }
    });
  },
};
</script>
<style scoped>
ul {
  display: inline-block;
  text-align: left;
  list-style: none;
  padding: 0;
  text-indent: -21px;
  margin-left: 21px;
}

ul.languages {
  padding: 5px;
  margin: 0px;
}
li.stack {
  text-indent: 0;
}
li.taches {
  text-indent: -32px;
  margin-left: 32px;
}

li.arrow-circle::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/arrow-alt-circle-right-regular.png");
}
li.white-hat::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/white-hat.png");
}
</style>
