<template>
  <div class="container">
    <div class="row container-title col-md-12">
      <h2>formation</h2>
    </div>
    <div class="row">
      <div class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>en classe</h4>
          </div>
          <div :key="cours.id" v-for="cours in enClasse">
            <h5 class="date">
              {{ cours.date }}
            </h5>
            <ul>
              <li class="grad">{{ cours.programme }}</li>
              <li class="arrow">{{ cours.specialite }}</li>
              <li class="arrow" v-show="cours.havePlus">{{ cours.plus }}</li>
              <li class="school">{{ cours.lieu }}</li>
              <ul
                v-for="(l, i) in cours.language"
                :key="`cours.language-${i}`"
                class="language_cours"
              >
                <li class="stack">
                  <b-badge pill variant="dark">{{ l }}</b-badge>
                </li>
              </ul>
            </ul>
          </div>
        </section>
      </div>
      <div id="formation_enligne" class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>en ligne</h4>
          </div>
          <div :key="cours.id" v-for="cours in enLigne">
            <h5 class="date">
              {{ cours.date }}
            </h5>
            <ul v-for="(f, i) in cours.formation" :key="`cours.formation-${i}`">
              <li class="location">
                {{ f.lieu }}
              </li>
              <ul
                class="languages"
                v-for="(c, j) in f.cours"
                :key="`cours.formation.cours-${j}`"
              >
                <li class="stack">
                  <a :href="c.url" target="_blank" rel="noopener noreferrer"
                    ><b-badge pill variant="primary"
                      >{{ c.language }}
                      <i class="fas fa-external-link-alt"></i></b-badge
                  ></a>
                </li>
              </ul>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormationComponent",
  props: {
    enClasse: { type: Array, require: true },
    enLigne: { type: Array, require: true },
  },
  created() {
    this.enClasse.forEach((d) => {
      if (typeof d === "object") {
        if (Array.isArray(d.programme)) d.isProgArray = true;
        if (d.hasOwnProperty("plus")) d.havePlus = true;
        if (d.hasOwnProperty("language")) d.haveLang = true;
      }
    });
    this.enLigne.forEach((d) => {
      if (typeof d === "object") {
        if (Array.isArray(d.programme)) d.isProgArray = true;
        if (d.hasOwnProperty("plus")) d.havePlus = true;
        if (d.hasOwnProperty("language")) d.haveLang = true;
      }
    });
  },
};
</script>
<style scoped>
ul {
  display: inline-block;
  text-align: left;
  list-style: none;
  text-indent: -21px;
  margin-left: 21px;
}
ul.language_cours {
  padding: 5px;
  margin: 0px;
}
li.stack {
  text-indent: 0;
}
li.grad::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/grad-hat.png");
}
li.arrow::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/arrow-alt-circle-right-regular.png");
}
li.school::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/school.png");
}
li.location::before {
  margin-right: 1px;
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/img/location.png");
}
</style>
