<template>
  <div class="container">
    <div class="row">
      <div class="container-title col-md-12">
        <h2>compétences</h2>
      </div>
    </div>
    <div class="row">
      <div id="techno" class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>technologies</h4>
            <div class="list">
              <b-tabs active-nav-item-class="bg-dark" small pills vertical>
                <b-tab>
                  <template v-slot:title>
                    <b class="tab-title">à l'aise</b>
                  </template>
                  <ul>
                    <div :key="i" v-for="(t, i) in techno">
                      <li class="arrow">{{ t }}</li>
                    </div>
                  </ul>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <b class="tab-title">j'apprends</b>
                  </template>
                  <ul>
                    <div :key="i" v-for="(l, i) in learning">
                      <li class="arrow">
                        {{ l }}
                      </li>
                    </div>
                  </ul>
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <b class="tab-title">sur la liste</b>
                  </template>
                  <ul>
                    <div :key="i" v-for="(t, i) in todo">
                      <li class="arrow">
                        {{ t }}
                      </li>
                    </div>
                  </ul>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </section>
      </div>
      <div class="container-db col-md-5">
        <section>
          <div class="container-db-title">
            <h4>aptitudes</h4>
          </div>
          <ul>
            <div :key="i" v-for="(a, i) in aptitude">
              <li class="arrow">{{ a }}</li>
            </div>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompetencesComponent",
  props: {
    aptitude: { type: Array, required: true },
    techno: { type: Array, required: true },
    learning: { type: Array, required: true },
    todo: { type: Array, required: true },
  },
};
</script>

<style scoped>
.tab-title {
  color: var(--main-front-color);
  font-weight: bolder;
}
.tab-title:hover {
  color: white;
}
.list {
  min-height: 10em;
  max-width: 75%;
}
ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 7em;
}
</style>
