<template>
  <div>
    <Formation :enClasse="formation_enclasse" :enLigne="formation_enligne" />
    <Experience :expInfo="experience_info" :expArt="experience_art" />
    <Competence
      :techno="stack"
      :aptitude="aptitude"
      :learning="learning"
      :todo="todo"
    />
    <!-- <Interet :intOui="interet_oui" :intNon="interet_non" />-->
  </div>
</template>
<script>
import Formation from "../components/Formation.vue";
import Competence from "../components/Competence.vue";
import Experience from "../components/Experience.vue";

export default {
  name: "CVView",
  components: {
    Formation,
    Experience,
    Competence,
  },

  data() {
    return {
      formation_enclasse: [],
      formation_enligne: [],
      experience_info: [],
      experience_art: [],
      aptitude: [],
      stack: [],
      learning: [],
      todo: [],
      interet_oui: [],
      interet_non: [],
    };
  },
  async created() {
    const json = await fetch("json/cv.json");
    const data = await json.json();
    const keys = Object.keys(data);

    await keys.forEach((key) => {
      switch (key) {
        case "techno":
          this.stack = data[key];
          break;
        case "learning":
          this.learning = data[key];
          break;
        case "todo":
          this.todo = data[key];
          break;
        case "formation_enclasse":
          this.formation_enclasse = data[key];
          break;
        case "formation_enligne":
          this.formation_enligne = data[key];
          break;
        case "experience_info":
          this.experience_info = data[key];
          break;
        case "experience_art":
          this.experience_art = data[key];
          break;
        case "aptitude":
          this.aptitude = data[key];
          break;
        case "interet_oui":
          this.interet_oui = data[key];
          break;
        case "interet_non":
          this.interet_non = data[key];
          break;
      }
    });
  },
};
</script>
<style>
.container-db {
  margin: 5px auto;
}
.container-title > h2 {
  color: var(--main-front-color);
  border-bottom: 2px solid var(--main-front-color);
  margin-bottom: 5px;
  margin-left: 15px;
  position: relative;
  right: 20px;
}
.container-db-title > h4 {
  border-bottom: 2px solid var(--main-front-color);
  margin-bottom: 5px;
  position: relative;
  text-align: left;
  right: 20px;
}
.date {
  margin: 0;
  padding-top: 5px;
}
</style>
