<template>
  <div>
    <CVView />
  </div>
</template>
<script>
import CVView from "./views/CV.vue";
import axios from "axios";
export default {
  name: "App",
  components: {
    CVView,
  },
  data() {
    return {
      username: "",
      login: process.env.VUE_APP_URL_LOGIN,
    };
  },
  methods: {
    async getAuth() {
      if (!this.isLogged) {
        await axios
          .get(this.login + "/check.php", {
            params: {
              username: this.$cookies.get("user"), //todo if not logged, would be !isset2
            },
          })
          .then(() => {
            this.isLogged = true;
            this.username = this.$cookies.get("user");
          })
          .catch(() => {
            this.isLogged = false;
            window.location.href = this.login + "/index.php?must_login";
          });
      }
    },
  },
  async created() {
    await this.getAuth();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
@import url("https://cdn.jsdelivr.net/gh/tonsky/FiraCode@5.2/distr/fira_code.css");
:root {
  --main-front-color: #39566f;
  --main-back-color: #212121;
  --main-grey: grey;
  --second-front-color: #2c3e50;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #212121;
}
#app {
  font-family: "Fira Code", sans-serif;
}
.stack {
  max-width: 75%;
}

.container {
  max-width: 95%;
  margin: 30px auto;
  padding: 30px;
  overflow: auto;
  border: 1px solid #39566f;
  border-radius: 10px;
  color: white;
  box-shadow: 0 4px 15px 0 grey, 0 4px 15px 0 grey;
}

ul {
  list-style: none;
  padding: 5px;
  margin: 0;
}
.arrow::before {
  content: "";
  display: inline-block;
  height: 1em;
  width: 1em;
  position: relative;
  top: 3px;
  margin-right: 1px;
  margin-left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../src/assets/img/arrow-right.png");
}
</style>
