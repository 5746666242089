import Vue from "vue";
import {
  BootstrapVue,
  IconsPlugin
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import cookies from "vue-cookies";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(cookies);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");